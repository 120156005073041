import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import { Button, Divider, Space, Table, Typography } from "antd"
import PageContent from "../components/page-content"
import { graphql, Link, navigate } from "gatsby"
import { JsonDebugger } from "../components/json-debugger"
import { GatsbyImage } from "gatsby-plugin-image"
const { Title } = Typography
export const query = graphql`
  {
    allPrismicAffiliate {
      edges {
        node {
          id
          data {
            name
            active
            url {
              url
              target
            }
            type {
              document {
                ... on PrismicAffiliateType {
                  id
                  data {
                    name
                  }
                }
              }
            }
            image {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
const Affiliates = ({ data }) => {
  //   if (!data) return null
  const columns = [
    {
      title: "Name",
      key: "image",
      width: 200,
      colSpan: 2,
      align: "left",
      render: (text, record) => {
        return (
          <a href={record.url.url}>
            <GatsbyImage
              image={record.image.gatsbyImageData}
              alt={record.image.alt}
            />
          </a>
        )
      },
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      colSpan: 0,
      //   width: 300,
      render: (text, record) => {
        return record.url ? <a href={record.url.url}>{text}</a> : text
      },
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Category",
      dataIndex: "type",
      key: "type",
      width: 200,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
  ]

  const dataSource = data.allPrismicAffiliate.edges
    .map(({ node }) => {
      return {
        key: node.id,
        name: node.data.name,
        type: node.data.type.document.data.name,
        url: node.data.url,
        image: node.data.image,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Layout>
      <Seo title={"Affiliates"} />
      <PageContent>
        <Title>Affiliates</Title>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ position: [] }}
        />
        <Divider />
        <Typography.Paragraph style={{ textAlign: "center" }}>
          If you are interested in joining our affiliate directory{" "}
          <Link to="/affiliate-program/">click here</Link> to apply.
          {/* <Button onClick={() => navigate("/affiliate-program/")}>
            Giddy Up
          </Button> */}
        </Typography.Paragraph>
        {/* <JsonDebugger json={data} /> */}
      </PageContent>
    </Layout>
  )
}

export default Affiliates
